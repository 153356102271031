import React from 'react';
import { Link } from "react-router-dom";

import '../stylesheets/classic_theme.css';


const IconButton = (props)=>{
	const attr = props.attr;

	return(
		<Link to={attr.dest}>
			<button className="theme-iconed-btn d-flex align-items-center justify-content-center btn-md">
				<span className="material-symbols-outlined material-icons icon-sm">
					{attr.icon}
				</span>
				<span>{attr.label}</span>
			</button>
		</Link>
	);
}


export default IconButton