import React from 'react';


import IconButton from '../components/icon_btn';


const StudentManagerMenu = ()=>{
	return(
		<div className="StudentManagerMenu row">
			<div class="col-md-12 d-flex align-items-center justify-content-end">

				<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@64,400,0,0" />

				<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

				<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

				<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />


				<IconButton attr={{
					icon : "patient_list",
					label : "Student List",
					class : "btn-md",
					dest : "/",
				}} />

				<IconButton attr={{
					icon : "group_add",
					label : "Register New Student",
					class : "btn-md",
					dest : "/student/manage/register",
				}} />

				<IconButton attr={{
					icon : "history_edu",
					label : "Enroll Student",
					class : "btn-md",
					dest : "/",
				}} />

				<IconButton attr={{
					icon : "credit_score",
					label : "Encode Grade",
					class : "btn-md",
					dest : "/",
				}} />
			</div>
		</div>
	);
}


export default StudentManagerMenu;