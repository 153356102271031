import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';



import Root from './pages/root';

import AdminDashboard from './pages/admin_dashboard/admin_dashboard';

import StudentManagerPanel from './pages/student_manage/student_manager_panel';
import StudentRegister from './pages/student_manage/register_student';

const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route path="/Admin/Dashboard" element={<AdminDashboard />} />
        <Route path="/student/manage" element={<StudentManagerPanel />} />
        <Route path="/student/manage/register" element={<StudentRegister />} />
      </Route>
    )
);


function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
