import React from 'react';
	

import StudentManagerMenu from './navigation_menu';
import '../stylesheets/classic_theme.css';
import '../stylesheets/content_panel.css';

const StudentManagerPanel = ()=>{
	return(
		<div class="StudentManagerPanel panel">
			<div class="container-fluid">
				<div class="subpanel">
					<StudentManagerMenu />
				</div>
			</div>
		</div>
	);
}


export default StudentManagerPanel;