import React from 'react';


const AdminDashboard = ()=>{
	return(
		<div className="AdminDashboard panel">
			<h1>Admin</h1>
		</div>
	);
}


export default AdminDashboard;