import React from 'react';

import '../stylesheets/sidebar.css';


const SideBar = ()=>{
	return (
		<div class='SideBar sidebar'>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

			<form action="/Admin/Dashboard" mehtod="get">
				<button type="submit" class='btn right hint--rounded hint--bounce text-color-main' data-hint='Dashboard'>
					<span class="material-symbols-outlined">
						dashboard
					</span>
					<span class='tab-name'>dashboard</span>
				</button>
			</form>

			<form action="/student/manage" mehtod="get">
				<button type="submit" class='btn right hint--rounded hint--bounce text-color-main' data-hint='Student Manager'>
					<span class="material-symbols-outlined">
						recent_actors
					</span>
					<span class='tab-name'>Students</span>
				</button>
			</form>

			<form action="#" mehtod="get">
				<button type="submit" class='btn right hint--rounded hint--bounce text-color-main' data-hint='Subject Manager'>
					<span class="material-symbols-outlined">
						note_stack_add
					</span>
					<span class='tab-name'>Subjects</span>
				</button>
			</form>

			<form action="#" method="post">
				<button type="submit" class='btn text-color-main'>
					<span class="material-symbols-outlined">
						logout
					</span>
					<p class='tab-name'>logout</p>
				</button>
			</form>
		</div>
	);
}


export default SideBar;