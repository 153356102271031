import React from 'react';


const RegistrationForm = ()=>{
	return(
		<div className="row">
			<div className="col-md-12">
				<form action="#" method="post">
				<p className="theme-header header-sm"> Personal Information </p>
				<div className="row pt-5">
					<div className="col-md-4">
						<label className="form-label">First Name</label>
						<input type="text" className="form-control theme-input-field" name="first_name" required />
					</div>
					<div className="col-md-4">
						<label className="form-label">Middle Name</label>
						<input type="text" className="form-control" name="middle_name" required/>
					</div>
					<div className="col-md-4">
						<label className="form-label">Last Name</label>
						<input type="text" className="form-control" name="last_name" required/>
					</div>
				</div>


				<div className="row pt-5">
					<div className="col-md-4">
						<label className="form-label">Age</label>
						<input type="number" className="form-control" name="age" required />
					</div>
					<div className="col-md-4">
						<label className="form-label">Birthdate</label>
						<input type="date" className="form-control" name="birthdate" required/>
					</div>
					<div className="col-md-4">
						<label className="form-label">gender</label>
						<select className="form-select" name="gender">
							<option selected>--Select Gender--</option>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
				</div>

				<div className="row pt-4">
					<div className="col-md-12">
						<label className="form-label">Home Address</label>
						<input type="text" className="form-control" name="home_address"/>
					</div>
				</div>

				<div className="row pt-4">
					<div className="col-md-6">
						<label className="form-label">Personal Email</label>
						<input type="email" className="form-control" name="personal_email" required/>
					</div>
					<div className="col-md-6">
						<label className="form-label">Contact Number</label>
						<input type="number" className="form-control" name="contact_number" required/>
					</div>
				</div>

				<p className="theme-header header-sm"> Account Register </p>
				<div className="row">
					<div className="col-md-12">
						<div className="row pt-2">
							<div className="col-md-8">
								<label className="form-label">Institutional Email</label>
								<input type="text" className="form-control" name="school_email" required/>
							</div>
							<div className="col-md-4">
								<label className="form-label">Domain</label>
								<input className="form-control" value="@institutionalemail.com" disabled/>
							</div>
						</div>
					</div>
				</div>

				<label className="form-label mt-3">Password</label>
				<input type="password" className="form-control" name="password" required/>

				<label className="form-label">Repeat Password</label>
				<input type="password" className="form-control" name="repeat-password" required/>

				<button className="theme-btn-primary theme-btn mt-3"> Submit Registration </button>
			</form> 
			</div>
		</div>
	);
}


export default RegistrationForm;